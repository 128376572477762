<template>
  <div class="accounting-create-assignment pt-4 pb-8">
    <p class="title pb-4">Nachträgliche Zuordnung</p>

    <p>Mit dieser Funktion kannst du diesem Angebot Accounting und Rechnungspositionen zuweißen die bisher noch nicht zugewiesen sind.</p>

    <vs-row class="mt-5">
      <ul class="flex assignment-list">
        <li class="p-2 pl-6 pr-6" v-bind:class="[$route.name === 'offers-accounting-assignment-positions' ? 'is-active' : '']">
          <router-link
              :to="{name:'offers-accounting-assignment-positions'}"
              class="NavigationItem__router-link">Abrechnungspositionen
          </router-link>
        </li>
        <li class="p-2 pl-6 pr-6" v-bind:class="[$route.name === 'offers-accounting-assignment-invoice-items' ? 'is-active' : '']">
          <router-link
              :to="{name:'offers-accounting-assignment-invoice-items'}"
              class="NavigationItem__router-link"> Rechnungspositionen
          </router-link>
        </li>
      </ul>
      <vs-col vs-type="flex"  class="pl-4 pr-4 content w-full" vs-justify="left"  vs-w="10">
        <transition :name="routerTransition" mode="out-in">
          <router-view @changeRouteTitle="changeRouteTitle"></router-view>
        </transition>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>

import themeConfig from '@/../themeConfig.js'

export default {
  name: "AccountingAssignment",
  components: {
  },
  data() {
    return {
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle || '',
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    }
  },
  watch: {
    "$route"() {
      this.routeTitle = this.$route.meta.pageTitle
    },
  }
}
</script>

<style lang="scss">
.accounting-create-assignment {
  .title {
    font-weight: bold;
    font-size: 15px;
  }
  .assignment-list {
    li {
      &.is-active {
        border-bottom: 0;
        background-color: #f3f3f3;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        a {
          color: #e24733;
        }
      }
    }
  }
  .content {
    background-color: #f3f3f3;
  }
}
</style>
